import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Image } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

export interface Props {
  firstBlockCTA?: ButtonProps
  firstBlockDescription?: string
  firstBlockImage?: ImageProps
  firstBlockTitle?: string
  secondBlockCTA?: ButtonProps
  secondBlockDescription?: string
  secondBlockImage?: ImageProps
  secondBlockTitle?: string
}

export const TwoBlocks = memo(function TwoBlocks({
  firstBlockCTA,
  firstBlockDescription,
  firstBlockImage,
  firstBlockTitle,
  secondBlockCTA,
  secondBlockDescription,
  secondBlockImage,
  secondBlockTitle,
}: Props) {
  return (
    <Container>
      <Fade>
        <Blocks row wrap>
          <Block>
            {firstBlockImage ? <Image {...firstBlockImage} /> : null}
            <Wrapper>
              {firstBlockTitle ? <Title>{firstBlockTitle}</Title> : null}
              {firstBlockDescription ? (
                <Description
                  dangerouslySetInnerHTML={{ __html: firstBlockDescription }}
                />
              ) : null}
              {firstBlockCTA ? <CTA variant="L" {...firstBlockCTA} /> : null}
            </Wrapper>
          </Block>
          <Block>
            {secondBlockImage ? <Image {...secondBlockImage} /> : null}
            <Wrapper>
              {secondBlockTitle ? <Title>{secondBlockTitle}</Title> : null}
              {secondBlockDescription ? (
                <Description
                  dangerouslySetInnerHTML={{ __html: secondBlockDescription }}
                />
              ) : null}
              {secondBlockCTA ? <CTA variant="L" {...secondBlockCTA} /> : null}
            </Wrapper>
          </Block>
        </Blocks>
      </Fade>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  position: relative;
`

const Blocks = styled(FlexBox)`
  padding: 0 10vw;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 40%;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  @media (max-width: 1199px) {
    padding: 0 8.454vw;
  }

  @media (max-width: 767px) {
    padding-bottom: 3.75rem;
    &:after {
      display: none;
    }
  }
`

const Block = styled.div`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  padding-bottom: 50%;
  position: relative;
  &:first-of-type {
    margin-top: 3.75rem;
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    aspect-ratio: 1;
    width: 100%;
    padding-bottom: 0;
    &:first-of-type {
      margin-top: 0;
      margin-bottom: 1.875rem;
    }
  }
`

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 3.75rem 3.75rem;
  z-index: 2;

  @media (max-width: 1199px) {
    padding: 0 2.25rem 2.25rem;
  }

  @media (max-width: 767px) {
    padding: 0 1.875rem 1.875rem;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 2.25rem;
  text-transform: uppercase;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.625rem;
  margin-top: 0.8125rem;
`

const CTA = styled(Button)`
  margin-top: 2.5rem;

  @media (max-width: 1199px) {
    height: 2.875rem;
    font-weight: 400;
    margin-top: 1.5rem;
    padding: 0 1.5rem;
  }
`
